@import 'side-panel-tabs';
@import 'side-panel-edit-content';
@import 'side-panel-add-content';

.side-panel {
  width: $builder-side-panel-width;

  &__tabs__container {
    padding: 17px 15px 280px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-left: 1px solid $builder-grey-30;
  }
}
